<template>
  <div class="row">
    <div class="col-sm-3">
      <RWCard title="Overview" :bodyFit="true">
        <template #body>
          <b-list-group flush>
            <b-list-group-item class="text-truncate"
              >Information</b-list-group-item
            ></b-list-group
          >
        </template>
      </RWCard>
      <RWCard title="Actions" :bodyFit="true">
        <template #body>
          <b-list-group flush>
            <b-list-group-item class="text-truncate"
              >Request Cancellation</b-list-group-item
            ></b-list-group
          >
        </template>
      </RWCard>
    </div>
    <div class="col-sm-8">
      <!--  banner    -->
      <RWCard
        :title="status.record.name + ' - ' + status.record.domain"
        :bodyFit="true"
      >
        <template #body>
          <div class="well" id="banner">
            <p style="margin-top: 260px" class="text-center"></p>
            &nbsp;
            <div class="text-center my-3">
              <template v-if="status.record.status === 'Active'">
                <!-- status awaiting configuration -->
                <template
                  v-if="
                    typeof account.record.vendor !== 'undefined' &&
                      typeof account.record.vendor.status_request !==
                        'undefined' &&
                      account.record.vendor.status_request ===
                        'awaiting configuration'
                  "
                >
                  <div class="alert alert-warning m-4">
                    Menunggu Konfigurasi. Diperlukan tindakan pengguna! <br />
                    Silakan klik tombol
                    <span class="font-weight-bold">Manage SSL</span> untuk
                    melengkapi data dan validasi SSL.
                  </div>
                </template>

                <b-link :to="{ path: `${id}/manage` }" class="btn btn-warning"
                  ><i class="fa fa-lock"></i> Manage SSL</b-link
                >
              </template>

              <button v-else class="btn btn-danger">
                <i class="fa fa-ban"></i> {{ status.record.status }}
              </button>
            </div>
          </div>
        </template>
      </RWCard>

      <div class="row">
        <div class="col-12 col-sm-6">
          <RWCard title="Product Detail" :bodyFit="true">
            <template #body>
              <div class="m-4" v-if="status.loading">
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
              </div>
              <template v-else>
                <div class="alert alert-danger m-4" v-if="!status.success">
                  {{ status.error.message }}
                </div>
                <table class="table table-striped" v-else>
                  <tbody>
                    <tr>
                      <td>Status</td>
                      <td>:</td>
                      <td>{{ status.record.status }}</td>
                    </tr>
                    <tr>
                      <td>Service</td>
                      <td>:</td>
                      <td>{{ status.record.name }}</td>
                    </tr>
                    <tr>
                      <td>Registration date</td>
                      <td>:</td>
                      <td>{{ status.record.regdate }}</td>
                    </tr>
                    <tr>
                      <td>Billing Cycle</td>
                      <td>:</td>
                      <td>{{ status.record.recurring }}</td>
                    </tr>
                    <tr>
                      <td>Next Due Date</td>
                      <td>:</td>
                      <td>{{ status.record.duedate }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </template>
          </RWCard>
        </div>
        <div class="col-12 col-sm-6">
          <RWCard title="Informasi Account" :bodyFit="true">
            <template #body>
              <div class="m-4" v-if="account.loading">
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
              </div>
              <template v-else>
                <div class="alert alert-danger m-4" v-if="!account.success">
                  {{ account.error.message }}
                </div>
                <table class="table table-striped" v-else>
                  <tbody>
                    <tr>
                      <td>Domain</td>
                      <td>:</td>
                      <td>{{ status.record.domain }}</td>
                    </tr>
                    <tr>
                      <td>Periode</td>
                      <td>:</td>
                      <td>
                        {{ mx_recurring_to_number(status.record.recurring) }}
                      </td>
                    </tr>
                    <tr v-if="account.record.vendor !== null">
                      <td>Metode Validasi</td>
                      <td>:</td>
                      <td>
                        {{ account.record.vendor.dcvmethod }}
                      </td>
                    </tr>
                    <tr>
                      <td>Status Request</td>
                      <td>:</td>
                      <td v-if="account.record.vendor !== null">
                        <div
                          v-html="
                            mx_badge_ssl_status_request(
                              account.record.vendor.status_request
                            )
                          "
                        ></div>
                      </td>
                      <td v-else>
                        <span class="badge badge-danger"
                          >Awaiting Configuration</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </template>
          </RWCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { ApiURL, util } from "@/common/mixins/general";

export default {
  name: "ssl-single",
  mixins: [ApiURL, util],
  components: { RWCard },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      status: {
        record: {},
        loading: true,
        success: false,
        error: {}
      },
      account: {
        record: {},
        loading: true,
        success: false,
        error: {}
      }
    };
  },
  methods: {
    /**
     * --------------------------------------------------
     * request status SSL aktif / tidak
     * yang selanjutnya akan diarahkan ke page aktif / tidak
     * --------------------------------------------------
     */
    getStatusSSL: function() {
      this.http
        .get(this.end.ssl.status, this.id)
        .then(({ data }) => {
          this.status.record = data.data.status;
          this.status.success = true;
        })
        .catch(error => {
          this.status.error = error;
        })
        .finally(() => {
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Product", route: "product" },
            { title: "SSL", route: "product/ssl" },
            {
              title: this.status.record.domain,
              route: "product/ssl/" + this.id
            }
          ]);
          this.status.loading = false;
        });
    },

    /**
     * --------------------------------------------------
     * get an account ssl and get data konfigurasi juga
     * --------------------------------------------------
     */
    getAccountSSL: function() {
      this.http
        .get(this.end.ssl.account, this.id)
        .then(({ data }) => {
          this.account.record = data.data.account;
          this.account.success = true;
        })
        .catch(error => {
          this.account.error = error;
        })
        .finally(() => {
          this.account.loading = false;
        });
    }
  },
  mounted() {
    this.getAccountSSL();
    this.getStatusSSL();
  }
};
</script>

<style scoped>
#banner {
  background-image: url("/assets/rw/rwpanel-banner.jpg");
  background-color: #33aae2;
  background-repeat: no-repeat;
  background-position: 50% 0;
}
</style>
